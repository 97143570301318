@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel-container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .carousel-image-container {
    position: relative;
    height: 550px;
  }
  
  .carousel-image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .carousel-image.active {
    opacity: 1;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
  }
  
  .prev-button {
    left: 10px;
  }
  
  .next-button {
    right: 10px;
  }
  

.fonth {
    font-size: 35px;
}
.fonts {
    font-size: 25px;
}

  @media (min-width: 640px) {
    /* Styles for small screens */
    .fonth {
        font-size: 38px;
    }
    .fonts {
        font-size: 28px;
    }
    
  }
  
  /* Medium screens (md: 768px and up) */
  @media (min-width: 768px) {
    /* Styles for medium screens */
    .fonth {
        font-size: 45px;
    }
    .fonts {
        font-size: 35px;
    }
    
  }
  
  /* Large screens (lg: 1024px and up) */
  @media (min-width: 1024px) {
    /* Styles for large screens */
  }
  
  /* Extra-large screens (xl: 1280px and up) */
  @media (min-width: 1280px) {
    /* Styles for extra-large screens */
  }
  
  /* 2XL screens (2xl: 1536px and up) */
  @media (min-width: 1536px) {
    /* Styles for extra-extra-large screens */
  } 




.button-zam {
    border:#FE4D11 0.5rem solid;
    color: #FE4D11;
    background-color: #ffffff;
    border-radius: 1000px;
    padding: 0.78rem 4.9rem;
    font-size: 25px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;

    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.5s ease, padding 0.4s ease, color 0.9s ease, border 0.5s ease;
}

/*.button-zam::after {
    content:'';
    position: absolute;
    height: 120%;
    width: 120%;
    
    border-radius: 1000px;
    background-color: linear-gradient(to bottom right, red, blue );
    z-index: 1;


}*/


.car-t {
    transition: transform 0.2s ease;
}
.car-z {
    transition: transform 0.2s ease;
}
.car-t:hover {
    transform: scale(1.15);
}

.car-z:hover {
    transform: scale(1.15);
}

.button-zam:hover{
    background-color: #232222; 
    transform: scale(1.1);
    border:#FE4D11 0.4rem solid;
    color: #FE4D11;

    border-radius: 307px;
    padding: 1rem 6rem;
    font-size: 28px;
}

.button-zam img {
    width: 45px;
    height: 35px;
    padding-right: 10px;
}

@font-face {
    font-family: 'condensed-j';
    src: url('./assets/RobotoCondensed-Bold.ttf');
}
.j-font {
    font-family: 'condensed-j';
}

@font-face {
    font-family: 'condensed-j2';
    src: url('./assets/RobotoCondensed-SemiBold.ttf');
}
.j-font2 {
    font-family: 'condensed-j2';
}

@font-face {
    font-family: 'condensed-j3';
    src: url('./assets/RobotoCondensed-Regular.ttf');
}
.j-font3 {
    font-family: 'condensed-j3';
}

@font-face {
    font-family: 'condensed-j4';
    src: url('./assets/Manrope-SemiBold.ttf');
}
.j-font4 {
    font-family: 'condensed-j4';
}

@font-face {
    font-family: 'condensed-j5';
    src: url('./assets/Inter-Regular.ttf');
}
.j-font5 {
    font-family: 'condensed-j5';
}
@font-face {
    font-family: 'condensed-j3';
    src: url('./assets/Inter-Medium.ttf');
}
.j-font6 {
    font-family: 'condensed-j3';
}
@font-face {
    font-family: 'condensed-j3';
    src: url('./assets/Inter-Light.ttf');
}
.j-font7 {
    font-family: 'condensed-j3';
}

body {
    margin: 0px;
    overflow-x: hidden;
}


.bg-img-second {
    background-image: url(assets/bg-img-second.png);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center;
}


.bg-logo {
    background-image: url(assets/logo.png);
    height: 100%;
    width: 100%;
}

.button-main {
    display: inline-flex;
    align-items: center;
    background: rgb(236, 238, 240);
    border: #6d6969 0.3em solid;
    border-radius: 2.5em;
    padding-left: 5em;
    padding-right: 5em;
    padding-top: 1em;
    padding-bottom: 1em;
}

/*button glow properties*/

.button-second {
    /*padding: 1.2rem 5rem;*/
    border-radius: 1.9rem;
    border: 6px solid #FF5E1A;
    /*border: 1px solid red;*/
    color: #302c2c;
    font-size: 1.55rem;
    overflow: hidden;
}
/*
.button-second::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 3rem;
    height: 2rem;
    background-color: #323231;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    filter: blur(18px);
    animation: button-animation 7s linear infinite;
}
.button-second::after {
    content: "444 444 444";
    position: absolute;
    left: 0;
    top: 0;
    width: 99%;
    height: 99%;
    background-color: #bcafaf;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
}
*/
@keyframes button-animation {
    0% {
        left: 0;
        top: 0;
    }
    35% {
        left: 100%;
        top: 0;
    }
    50% {
        left: 100%;
        top: 100%;
    }
    85% {
        left: 0;
        top: 100%;
    }
}


@media screen and (max-width: 600px) {
    /* Add your styles here */
    .button-small {
        display: inline-flex;
        align-items: center;
        background: transparent;
        border: #F6FB1A 0.3em solid;
        border-radius: 2em;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 1em;
        padding-bottom: 1em;
    }
  }

.button-main:hover {
    border: rgb(243 244 246) 0.3em solid;
  }

body {
    margin: 0;
}

.bullet {
  margin-right: 8px; /* Adjust margin as needed */
  color: #333; /* Color of bullet point */
}
ul {
  padding-left: 0;
}

li {
  display: flex;
  align-items: baseline; /* Adjust alignment as needed */
  list-style-type: none;
}
